import React from "react";
import { useTranslation } from "react-i18next";
import { MingleLogo } from "./sidebar/MingleLogo";

/**
 * LoadingSkeleton component for displaying a loading state
 * Shows a skeleton sidebar and loading indicator while the app is loading
 */
export const LoadingSkeleton: React.FC = () => {
  const { t } = useTranslation("translation");

  return (
    <div className="flex min-h-screen min-w-screen">
      {/* Sidebar skeleton */}
      <div
        className="fixed w-72 z-50 overflow-hidden h-full px-2 py-1 lg:px-6 lg:py-4 mr-auto left-0 overflow-y-auto flex-col gap-4 dark:border-gray-200 bg-[#00003D] dark:bg-black transform transition-all duration-200 hidden lg:flex"
        data-theme="dark"
      >
        <div className="flex flex-row items-center mr-auto">
          <MingleLogo className="h-8 sm:h-10" />
        </div>

        {/* Skeleton placeholders for sidebar content */}
        <div className="skeleton h-36 w-full mb-4"></div>
        <div className="skeleton h-4 w-full"></div>
        <div className="skeleton h-4 w-full"></div>
        <div className="skeleton h-4 w-full"></div>
        <div className="skeleton h-4 w-full"></div>
        <div className="skeleton h-4 w-full"></div>
        <div className="skeleton h-4 w-full"></div>
      </div>

      {/* Content skeleton */}
      <div className="flex flex-col m-auto lg:pl-72 lg:w-[calc(100%_-_288px)] w-full">
        <div className="flex h-full w-full">
          <div className="flex justify-center flex-col gap-4 w-full">
            <progress className="progress progress-primary w-56 m-auto dark:progress-secondary"></progress>
            <h1 className="text-primary text-sm text-center dark:text-secondary">
              {t("pageLoading")}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};
